.contact-main h3 {
	margin-bottom:5px;
	font-size:1.4em;
	color:#222;
	text-align:center;
	font-weight: 300;
}
.contact-main h3 span {
	font-weight:800;
}
.contact-desc {
	color:#999;
	font-size:0.8em;
	text-align:center;
}
.contact-form-container, .other-methods-container {
	margin-bottom:120px;
}
.other-methods-container {

}
.other-methods-container h3 {
	margin-bottom:30px;
}
.contact-label {
	font-size:0.85em;
	color:#555;
	text-align:center;
}
.phone-link, .email-link {
	text-align:center;
	font-size:1.2em;
	display:block;
}
.phone-link {
	margin-bottom:10px;
}
/*.phone-link, .email-link {
	text-decoration:underline;
}*/


@media (max-width: 1199px) {}

@media (max-width: 1023px) {}

@media (max-width: 767px) {
  .contact-main h3 {
    font-size:1.2em;
    margin-bottom:16px;
  }
  .contact-label {
    text-align: left;
    font-size:0.8em;
  }
  .phone-link, .email-link {
    text-align: left;
    font-size:1em;
  }
  .contact-form-container, .other-methods-container {
    margin-bottom:60px;
  }
  // .bg-cont {
  //   &.contact {
  //     background-position: right 15px top 79px;
  //     background-size: 60%;
  //   }
  // }
}

@media (max-width: 479px) {}

@media (max-width: 479px) {}




@media (min-width: 320px) {}

@media (min-width: 480px) {
  .contact-label {
    text-align: center;
  }
  .phone-link, .email-link {
    text-align: center;
  }
}

@media (min-width: 768px) {
  // .bg-cont {
  //   &.contact {
  //     background-position: right 15px bottom 10px;
  //     background-size: 60%;
  //   }
  // }
}

@media (min-width: 1024px) {}

@media (min-width: 1200px) {}