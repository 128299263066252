.leaflet-container {
  height: 600px;
  width: 100%;
  margin: 0 auto;
}

.leaflet-container a {
  color:#D58248;
}

@media (max-width: 767px) {
  .leaflet-container {
    height: calc(100vh - 200px);
  }
}